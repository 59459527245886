import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import {
  SignInContainer,
  SignInBg,
  SignInCard,
  Logo,
  LogoContainer,
} from "./sign-in.styles";

const SignIn = ({ path }) => {
  return (
    <SignInContainer>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>
      <SignInCard>
        <SignInForm />
      </SignInCard>
      <SignInBg />
    </SignInContainer>
  );
};
export default SignIn;
