import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import NavbarDashboard from "../../components/navbar-dashboard/navbar-dashboard.component";
import Sidebar from "../../components/sidebar/sidebar.component";
import { getUserOrderHistory } from "../../store/orders/orders.action";
import { selectOrderHistory } from "../../store/orders/orders.selector";
import { selectCurrentUser } from "../../store/user/user.selector";
import { DashboardContainer, DashboardContent } from "./user-dashboard.styles";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const orderHistory = useSelector(selectOrderHistory);
  // console.log(orderHistory);
  // console.log(currentUser.uid);

  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser.uid);
      dispatch(getUserOrderHistory(currentUser.uid));
    }
    //
  }, [currentUser, orderHistory.status]);

  return (
    <DashboardContainer>
      <Sidebar />
      <DashboardContent>
        <NavbarDashboard />

        <Outlet />
      </DashboardContent>
    </DashboardContainer>
  );
};
export default UserDashboard;
