// import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/sign-in/sign-in.component";
import UserDashboard from "./pages/user-dashboard/user-dashboard.component";
import UserProfile from "./components/user-profile/user-profile.component";
import Orders from "./components/orders/orders.component";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { checkUserSession } from "./store/user/user.action";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // const unsubscribe = onAuthStateChangedListener((user) => {
    //   if (user) {
    //     createUserDocumentFromAuth(user);
    //   }
    //   dispatch(setCurrentUser(user));
    // });
    // return unsubscribe;
    dispatch(checkUserSession());
  }, []);
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />;
      <Route path="account/*" element={<UserDashboard />}>
        <Route index element={<Orders />} />
        <Route path="profile" element={<UserProfile />} />
      </Route>
    </Routes>
  );
};

export default App;

// <Route path="/" element={<SignIn />} />;

// <Route path="/" element={<Navigation />}>
// <Route index element={<Home />} />
// <Route path="about" element={<About />} />
// <Route path="contact" element={<Contact />} />
// <Route path="price" element={<WritingPackage />} />
// <Route path="privacy" element={<Privacy />} />
// <Route path="terms" element={<Terms />} />
// <Route path="faqs" element={<FAQS />} />
// </Route>

// <Route path="signin" element={<SignIn />} />
// <Route path="signup" element={<SignUp />} />
// <Route path="checkout" element={<Checkout />} />
// <Route path="account/*" element={<UserDashboard />}>
//   <Route element={<HomeDashboard />} />
//   <Route index element={<Orders />} />
//   <Route path="profile" element={<UserProfile />} />
// </Route>
