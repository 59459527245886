import {
  BaseButton,
  GoogleSignInButton,
  SignInButton,
  InvertedButton,
  BuyButton,
  InvertedBuyButton,
  ButtonSpinner,
  UploadButton,
  ModalYesButton,
  ModalNoButton,
} from "./button.styles";

export const BUTTON_TYPE_CLASSES = {
  base: "base",
  google: "google-sign-in",
  signIn: "sign-in",
  inverted: "inverted",
  buyinverted: "buyinverted",
  buy: "buy",
  upload: "upload",
  modalYes: "modalYes",
  modalNo: "modalNo",
};

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base) =>
  ({
    [BUTTON_TYPE_CLASSES.base]: BaseButton,
    [BUTTON_TYPE_CLASSES.google]: GoogleSignInButton,
    [BUTTON_TYPE_CLASSES.signIn]: SignInButton,
    [BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
    [BUTTON_TYPE_CLASSES.buyinverted]: InvertedBuyButton,
    [BUTTON_TYPE_CLASSES.buy]: BuyButton,
    [BUTTON_TYPE_CLASSES.upload]: UploadButton,
    [BUTTON_TYPE_CLASSES.modalYes]: ModalYesButton,
    [BUTTON_TYPE_CLASSES.modalNo]: ModalNoButton,
  }[buttonType]);

const Button = ({ children, buttonType, isLoading, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  return (
    <CustomButton disabled={isLoading} {...otherProps}>
      {isLoading ? <ButtonSpinner /> : children}
    </CustomButton>
  );
};

export default Button;
