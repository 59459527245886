import { UploadContainer, Input, Label } from "./uploadButton.styles";
import * as yup from "yup";

const UploadButton = ({
  name,
  label,
  type,
  vali,
  htmlFor,
  id,
  ...otherProps
}) => {
  return (
    <UploadContainer>
      <Label {...otherProps} htmlFor={htmlFor}>
        <Input type={type} id={id} name={name} hidden {...vali} />
        {label}
      </Label>
    </UploadContainer>
  );
};

export default UploadButton;
// <Input {...otherProps} />;
// {
//   label && (
//     <FormInputLabel shrink={otherProps.value.length}>{label}</FormInputLabel>
//   );
// }

// <label className="btn">
//   📸 Upload Img
//   <input
//     type="file"
//     onChange={uploadFile}
//     accept="image/x-png,image/gif,image/jpeg"
//   />
// </label>;
