import { useEffect, useState } from "react";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import UploadButton from "../uploadButton/uploadButton.component";
import {
  Group,
  GroupHeader,
  Line,
  ShortNote,
  ButtonContainer,
  FilePreviewCard,
  FilePreviewWrapper,
  ErrorContext,
} from "./upload.styles";

import CancelIcon from "@mui/icons-material/Cancel";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadSchema } from "../../validations/uploadValidation";
import { useForm } from "react-hook-form";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../utils/firebase/firebase.utils";
import { useDispatch } from "react-redux";
import {
  saveOrderHistory,
  uploadDeliveryFileStart,
} from "../../store/orders/orders.action";

const defaultFormFields = {
  deliveryFile: [],
};

const defaultErrorMessage = {
  jobtargetError: "",
  inputFileError: "",
  cardError: "",
};

const Upload = ({ orderId, userId, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(uploadSchema),
  });
  const dispatch = useDispatch();
  const [listFile, setListFile] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [percents, setPercents] = useState([]);
  const { deliveryFile } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
    setListFile([]);
  };

  const deleteFile = (event) => {
    const fileToDelete = Array.from(listFile).filter(
      (item, index) => index !== event
    );
    setListFile(fileToDelete);
  };
  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setListFile([...listFile, event.target.files[0]]);
    }
  };

  const uploadHandler = () => {
    console.log(orderId);
    console.log(userId);
    if (listFile.length === 0 || listFile === undefined) {
      setErrorMessage({
        ...errorMessage,
        inputFileError: "*** please upload your documents",
      });
      return;
    }
    setIsProcessing(true);
    Array.from(listFile).map((file) => {
      // console.log("loop");
      const storageRef = ref(
        storage,
        `/${userId}/${orderId}/delivery/${file.name}`
      );

      // progress can be paused and resumed. It also exposes progress updates.
      // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercents([...percents, percent]);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormFields((formFields) => ({
              ...formFields,
              deliveryFile: [
                ...formFields.deliveryFile,
                { filename: file.name, link: downloadURL },
              ],
            }));
            console.log(downloadURL);
          });
        }
      );
    });
  };

  useEffect(() => {
    if (deliveryFile.length > 0 && isProcessing === true) {
      if (deliveryFile.length === listFile.length) {
        dispatch(uploadDeliveryFileStart({ deliveryFile }, orderId));

        setIsProcessing(false);
        resetFormFields();
        handleClose();
      }
    }
  }, [deliveryFile]);

  return (
    <div>
      <form onSubmit={handleSubmit(uploadHandler)}>
        <Line />
        <Group>
          <GroupHeader>
            Upload Resume File & More Information File Here
            <span> (support .doc .docx .pdf)</span>
          </GroupHeader>

          <UploadButton
            type="file"
            onChange={handleFileChange}
            // accept=".docx, .pdf, .doc"
            htmlFor="formId"
            id="formId"
            label="Add File Here"
            name="upload"
            vali={register("upload")}
            require
          />
          <FilePreviewWrapper>
            {listFile &&
              Array.from(listFile).map((item, index) => (
                <FilePreviewCard key={index}>
                  <span>{item && item.name}</span>
                  <CancelIcon
                    style={{ color: "#EA5E5D" }}
                    onClick={() => deleteFile(index)}
                  />
                </FilePreviewCard>
              ))}
            {listFile.length === 0 && (
              <ErrorContext>{errorMessage.inputFileError}</ErrorContext>
            )}

            {errors.upload && (
              <ErrorContext>{errors.upload.message}</ErrorContext>
            )}
          </FilePreviewWrapper>
        </Group>

        <ButtonContainer>
          <Button isLoading={isProcessing} buttonType={BUTTON_TYPE_CLASSES.buy}>
            Upload
          </Button>
        </ButtonContainer>

        <ShortNote>
          By clicking the "Upload" button above, it will upload file to user and
          change status to "Ready"
        </ShortNote>
      </form>
    </div>
  );
};
export default Upload;
