import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";

export const SidebarContainer = styled.div`
  min-height: 100vh;
  flex: 1;
  /* position: fixed; */
  box-shadow: 0px 24px 44px rgb(0 0 0 / 8%);
`;
export const Top = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Center = styled.div`
  height: 80%;
  flex: 1;
  ul {
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      .icon {
        margin-right: 20px;
      }

      &:hover {
        background-color: #f8faff;
        .icon {
          color: #3377ff;
        }
        span {
          color: #3377ff;
        }
      }
    }
  }
`;

export const Bottom = styled.div`
  margin-top: auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0 10px;
    li {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      .icon {
        margin-right: 20px;
        color: #ea5e5d;
      }
      span {
        color: #ea5e5d;
        font-weight: 700;
      }
    }
  }
`;

export const Logo = styled(CoaonLogo)`
  /* position: absolute; */
  z-index: 10;
  width: 90px;
  height: auto;
  top: 2%;
  left: 2%;
`;

export const LogoContainer = styled.div`
  /* height: 100%;
  width: 90px;
  margin: 25px; */
  /* align-items: center;
  justify-content: center; */
  /* padding: 25px; */
  @media screen and (max-width: 800px) {
    width: 50px;
    /* padding: 0; */
  }
`;

export const NavLinks = styled.div`
  /* width: 50%;
  height: 100%; */
  /* display: flex;
  align-items: center; */
  /* justify-content: flex-end; */

  /* @media screen and (max-width: 800px) {
    width: 80%;
  } */
`;

export const Link = styled(NavLink)`
  cursor: pointer;
  font-size: 17px;

  color: #848484;
  /* justify-content: center;
  align-items: center; */
  &.active {
    li {
      background-color: #f8faff;
      .icon {
        color: #3377ff;
      }
      span {
        color: #3377ff;
      }
    }
  }

  &.base {
    li {
      background-color: yellow;
      .icon {
        color: #848484;
      }
      span {
        color: #848484;
      }
    }
  }
`;
