import { createAction } from "../../utils/reducer/reducer.utils";
import { ORDER_ACTION_TYPES } from "./orders.types";

export const saveOrderHistory = (
  collectionKey,
  objectsToAdd,
  orderId,
  userId,
  amount,
  cartItems
) =>
  createAction(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_START, {
    collectionKey,
    objectsToAdd,
    orderId,
    userId,
    amount,
    cartItems,
  });

export const saveOrderHistoryFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_FAILED, error);

export const getUserOrderHistory = () =>
  createAction(ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_START);

export const setUserOrderHistory = (history) =>
  createAction(ORDER_ACTION_TYPES.SET_USER_ORDER_HISTORY, history);

export const updateStatusOrderStart = (orderId, status) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_START, {
    orderId,
    status,
  });

export const updateStatusOrderSuccess = () =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS);

export const updateStatusOrderFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_FAILED, error);

export const uploadDeliveryFileStart = (objectsToAdd, orderId) =>
  createAction(ORDER_ACTION_TYPES.UPLOAD_DELIVERY_FILE_START, {
    objectsToAdd,
    orderId,
  });

export const uploadDeliveryFileSuccess = () =>
  createAction(ORDER_ACTION_TYPES.UPLOAD_DELIVERY_FILE_SUCCESS);

export const uploadDeliveryFileFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.UPLOAD_DELIVERY_FILE_FAILED, error);

// export const updateStatusOrderSuccess = (status) =>
//   createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS, status);
