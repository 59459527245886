import styled from "styled-components";

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  /* background-color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
`;

export const Label = styled.label`
  background-color: #27c8a1;
  padding: 15px;
  font-weight: 600;
  border: none;
  /* box-shadow: 1px 0 1px 1px #6b4559; */
  outline: none;
  color: white;
  display: inline-block;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid #27c8a1;
    color: #27c8a1;
  }
`;

export const Input = styled.input``;
