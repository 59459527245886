import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrderHistory,
  selectOrderHistoryIsLoading,
} from "../../store/orders/orders.selector";
import { TableWrapper } from "./orderTable.styles";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import { fetchFromAPI } from "../../utils/helpers";
import { updateStatusOrderStart } from "../../store/orders/orders.action";
import { Fragment, useEffect, useState } from "react";
import Modal from "../modal/modal.component";
import { AnimatePresence } from "framer-motion";
// import { selectCurrentUser } from "../../store/user/user.selector";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CancelIcon from "@mui/icons-material/Cancel";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Spinner from "../spinner/spinner.component";

const OrderTable = () => {
  const stripe = useStripe();
  const orders = useSelector(selectOrderHistory);
  // const currentUser = useSelector(selectCurrentUser);
  // console.log(orderHistory);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [uploadItem, setUploadItem] = useState("");
  const [userIdItem, setUserIdItem] = useState("");
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);

  const [orderHistory, setOrderHistory] = useState(orders);

  const [readyFile, setReadyFile] = useState([]);
  const isLoading = useSelector(selectOrderHistoryIsLoading);

  // const [itemToUpload, setitemToUpload] = useState(defaultItemUpload);

  const close = () => setModalOpen(false);
  const open = (uploadItemID, deliveryFile, userId) => {
    setModalOpen(true);
    setUploadItem(uploadItemID);
    setReadyFile(deliveryFile);
    setUserIdItem(userId);
    console.log("uesrID:" + userId);

    // console.log(refundID);
    // console.log(refundItem);
    // console.log(modalOpen);
  };

  useEffect(() => {
    setOrderHistory(orders);
  }, [orders]);

  const columns = [
    { field: "id", headerName: "Order ID", width: 300 },
    {
      field: "createdAt",
      headerName: "Order Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {moment(params.row.createdAt.toDate()).format("MM/DD/YYYY")}
          </div>
          // <div>{params.row.createdAt.toDate()}</div>
        );
        // return <div>{params.row.createdAt}</div>;
      },
    },
    {
      field: "deliveryAt",
      headerName: "Due Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {moment(params.row.deliveryAt.toDate()).format("MM/DD/YYYY")}
          </div>
          // <div>{params.row.createdAt.toDate()}</div>
        );
        // return <div>{params.row.createdAt}</div>;
      },
      // renderCell: (params) => {
      //   return moment(params.row.deliveryAt.nano).format("MM/DD/YYYY");
      // },
    },
    {
      field: "products",
      headerName: "Products",
      // type: "number",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "customername",
      headerName: "Customer",
      // type: "number",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.customername}</div>
            <div className="contact">
              {params.row.phone && (
                <a href={`tel:${params.row.phone}`} className="icon">
                  <PhoneIphoneIcon />
                </a>
              )}

              {params.row.email && (
                <a href={`mailto:${params.row.email}`} className="icon">
                  <EmailIcon />
                </a>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      // type: "number",
      minWidth: 120,
      flex: 1,

      renderCell: (params) => {
        return (
          <div>
            {!isEditStatus ? (
              <div className="status-container">
                <div
                  className={`statusStyle  
                  ${params.row.status === "Requested" ? "active" : ""} 
                  ${params.row.status === "In Progress" ? "inProgress" : ""} 
                  ${params.row.status === "More Info" ? "moreInfo" : ""}
                  ${params.row.status === "Ready" ? "ready" : ""}
                  ${params.row.status === "Canceled" ? "canceled" : ""}
          `}
                >
                  {params.row.status}
                </div>
                <div>
                  <EditIcon
                    className="edit-icon"
                    onClick={() => setIsEditStatus(!isEditStatus)}
                  />
                </div>
              </div>
            ) : (
              <div className="status-container">
                <PublishedWithChangesIcon
                  className="inprogress-status-icon"
                  onClick={() =>
                    handleUpdateStatus(
                      params.row.id,
                      params.row.uid,
                      "In Progress",
                      params.row.status
                    )
                  }
                />

                <InfoIcon
                  className="moreInfo-status-icon"
                  onClick={() =>
                    handleUpdateStatus(
                      params.row.id,
                      params.row.uid,
                      "More Info",
                      params.row.status
                    )
                  }
                />

                <FactCheckIcon
                  className="ready-status-icon"
                  onClick={() =>
                    handleUpdateStatus(
                      params.row.id,
                      params.row.uid,
                      "Ready",
                      params.row.status
                    )
                  }
                />
                <CancelIcon
                  className="cancle-status-icon"
                  onClick={() =>
                    handleUpdateStatus(
                      params.row.id,
                      params.row.uid,
                      "Canceled",
                      params.row.status
                    )
                  }
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "downloadLink",
      headerName: "Documents",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.downloadLink.map((link) => (
              <div className="downloadlink" key={link.link}>
                {link && (
                  <a
                    href={`${link.link}`}
                    onClick={() =>
                      handleClick(
                        params.row.id,
                        params.row.userId,
                        params.row.status
                      )
                    }
                  >
                    <FilePresentIcon className="icon" />
                    <span>{link.filename}</span>
                  </a>
                )}
              </div>
            ))}
          </div>
        );
      },
      // valueGetter: (params) =>
      //   // `${params.row.deliveryAt || ""} ${params.row.lastName || ""}`,
      //   `${params.row.deliveryFile || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "jobtarget",
      headerName: "Job Target",
      // type: "number"
      minWidth: 150,
      flex: 1,
    },

    {
      field: "Upload",
      headerName: "",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="upload">
            <Button
              isLoading={false}
              buttonType={BUTTON_TYPE_CLASSES.upload}
              onClick={() =>
                modalOpen
                  ? close()
                  : open(
                      params.row.id,
                      params.row.deliveryFile,
                      params.row.userId
                    )
              }

              // handdleRefundOrder(params.row.id)}
            >
              Upload
            </Button>
          </div>
        );
      },
    },
  ];

  const handleClick = (updateItemID, userId, status) => {
    if (status === "Requested") {
      dispatch(updateStatusOrderStart(updateItemID, "In Progress"));
    }
  };

  const handleUpdateStatus = (
    updateItemID,
    userId,
    updatestatus,
    currentstatus
  ) => {
    if (updatestatus !== currentstatus) {
      dispatch(updateStatusOrderStart(updateItemID, updatestatus));
    }
    setIsEditStatus(!isEditStatus);
  };
  // <span>$ {params.row.price || 0}</span>

  const handdleRefundOrder = async () => {
    if (!stripe) {
      return;
    }
    // console.log(stripe);
    // console.log(refundItem);
    setIsCancelLoading(true);

    const response = await fetchFromAPI("refund", {
      body: {
        orderId: uploadItem,
      },
    });

    // const paymentIntent = await stripe.paymentIntents.search({
    //   query: `status:\'succeeded\' AND metadata[\'order_id\']:\'52a965b9-5af1-4d45-8695-dc2db6716ce9\'`,
    // });

    // console.log(response);
    if (response.error) {
      alert(response.error);
      setIsCancelLoading(false);
    } else {
      if (response.status === "succeeded") {
        dispatch(updateStatusOrderStart(uploadItem, "Canceled"));
        setIsCancelLoading(false);
        close();
      }
      // const refund = await stripe.refunds.create({
      //   payment_intent: paymentIntent.data[0].id,
      // });
    }
    // return refund;
  };

  return (
    <TableWrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <DataGrid
            // key={orderHistory.orderID}

            getEstimatedRowHeight={() => 100}
            getRowHeight={() => "auto"}
            id={orderHistory.orderID}
            rows={orderHistory}
            // autoHeight
            columns={columns}
            pageSize={9}
            rowsPerPageOptions={[10]}
            pagination
            // {...data}
            // pageSize={auto}
            // rowsPerPageOptions={[10]}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: 1,
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
            // checkboxSelection
          />
          <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
          >
            {modalOpen && (
              <Modal
                modalOpen={modalOpen}
                handleClose={close}
                handleYes={handdleRefundOrder}
                isCancelLoading={isCancelLoading}
                readyFile={readyFile}
                title="Upload Document File to Client Here"
                subtitle="file 1"
                orderId={uploadItem}
                userId={userIdItem}
              />
            )}
          </AnimatePresence>
        </Fragment>
      )}
    </TableWrapper>
  );
};
export default OrderTable;

// onClick` listener to be a function, instead got a value of `object` type.

// ({params.row.status}) => (

//   if( (params.row.status === "Requested" )|| (params.row.status === "In Progress") ){
//      " statusStyle active"
//     } else if( params.row.staus === "More Info"){
//       "statusStyle moreInfo"
//     } else {
//       "statusStyle canceled"
//     }

//     ))}
