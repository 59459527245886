import styled from "styled-components";
import { SpinnerContainer } from "../spinner/spinner.styles";

export const BaseButton = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: rgb(43, 86, 243);
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgb(0, 52, 255, 1);
    /* background-color: white;
    color: black;
    border: 1px solid black; */
  }
`;

export const GoogleSignInButton = styled(BaseButton)`
  background-color: #4285f4;
  color: white;
  &:hover {
    background-color: #357ae8;
    border: none;
  }
`;

export const SignInButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.44);
  min-width: auto;
  padding: 0 20px 0 20px;
  /* border: 1px solid black; */
  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
    border: none;
  }
`;

export const InvertedButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 1);
  color: rgb(43, 86, 243);
  border: 1px solid rgb(43, 86, 243);
  &:hover {
    background-color: rgb(43, 86, 243);
    color: white;
    border: none;
  }
`;

export const BuyButton = styled(BaseButton)`
  border-radius: 0;

  padding: 0 50px 0 50px;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: rgb(43, 86, 243);
    border-radius: 0;

    border: 2px solid rgb(43, 86, 243);
  }
`;

export const InvertedBuyButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 1);
  color: rgb(43, 86, 243);
  border-radius: 0;
  padding: 0 50px 0 50px;
  border: 2px solid rgb(43, 86, 243);
  &:hover {
    background-color: rgb(43, 86, 243);
    color: white;
    border: none;
  }
`;
export const UploadButton = styled(BaseButton)`
  background-color: transparent;
  color: rgb(43, 86, 243);
  min-width: 80px;
  border-radius: 15px;
  padding: 0 5px;
  border: 2px solid rgb(43, 86, 243);
  font-size: 0.875rem;
  text-transform: none;

  width: auto;
  height: 30px;
  letter-spacing: 0.5px;
  line-height: 0px;

  &:hover {
    background-color: rgb(43, 86, 243);
    color: white;
    border: none;
  }
`;
export const ModalYesButton = styled(BaseButton)`
  background-color: transparent;
  color: rgb(43, 86, 243);
  min-width: 100px;
  border-radius: 15px;
  padding: 0 10px;
  border: 2px solid rgb(43, 86, 243);
  font-size: 0.875rem;
  text-transform: none;

  width: auto;
  height: 30px;
  letter-spacing: 0.5px;
  line-height: 0px;

  &:hover {
    background-color: rgb(43, 86, 243);
    color: white;
    border: none;
  }
`;

export const ModalNoButton = styled(BaseButton)`
  background-color: rgb(43, 86, 243);
  color: white;
  min-width: 100px;
  border-radius: 15px;
  padding: 0 10px;
  border: none;
  font-size: 0.875rem;
  text-transform: none;

  width: auto;
  height: 30px;
  letter-spacing: 0.5px;
  line-height: 0px;

  &:hover {
    background-color: rgb(0, 52, 255, 1);
    color: white;
    border: none;
  }
`;

export const ButtonSpinner = styled(SpinnerContainer)`
  width: 30px;
  height: 30px;
`;
