import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as SignInHeaderBg } from "../../assets/signin/signInBg.svg";

import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";

export const SignInContainer = styled.div`
  background-color: #f6f9fc;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: relative;
`;

export const SignInBg = styled(SignInHeaderBg)`
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 2;
  top: 0;
  left: 0;
`;

export const SignInCard = styled.div`
  background-color: rgba(255, 255, 255, 0.36);
  box-shadow: 0px 10px 41px rgba(255, 172, 172, 0.33);
  border-radius: 25px;
  padding: 50px 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  margin: auto;
`;

export const Logo = styled(CoaonLogo)`
  position: absolute;
  z-index: 10;
  width: 90px;
  height: auto;
  top: 2%;
  left: 2%;
`;

export const LogoContainer = styled(Link)`
  /* height: 100%;
  width: 90px;
  margin: 25px; */
  /* align-items: center;
  justify-content: center; */
  /* padding: 25px; */
  @media screen and (max-width: 800px) {
    width: 50px;
    /* padding: 0; */
  }
`;
