import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";

// export const handleSaveOrder = order => {
//   return new Promise((resolve, reject)=>{
//     const collectionRef = collection(db, "orders");

//   })
// }

// add Order after user pay
export const addOrder = async (
  collectionKey,
  objectsToAdd,
  orderId,
  userId,
  amount,
  cartItems
) => {
  const orderDocRef = doc(db, collectionKey, orderId);
  const orderSnapShot = await getDoc(orderDocRef);
  if (!orderSnapShot.exists()) {
    const { jobtarget, downloadLink } = objectsToAdd;

    var createdAt = new Date();
    var day = createdAt.getDay();
    var hour = createdAt.getHours();
    const deliveryAt = new Date(createdAt);
    //check if its sunday or saturday
    if (day === 0 || day === 6) {
      if (day === 0) {
        deliveryAt.setDate(deliveryAt.getDate() + 3);
      } else {
        deliveryAt.setDate(deliveryAt.getDate() + 4);
      }

      console.log("Currently closed on Saturday - Sunday.");
      // document.getElementById('example').innerHTML = "Currently closed on Saturday - Sunday.";
      // check if its between 9am and 11pm (inclusive)
    } else if (hour >= 9 && hour <= 17) {
      deliveryAt.setDate(deliveryAt.getDate() + 2);
      // console.log("Currently opened on Monday - Friday.");
      // document.getElementById('example').innerHTML = "Currently opened on Monday - Friday.";
    } else {
      deliveryAt.setDate(deliveryAt.getDate() + 3);
    }

    // if (createdAt.getTime() > date2.getTime())
    try {
      await setDoc(
        orderDocRef,
        {
          jobtarget,
          createdAt,
          deliveryAt,
          downloadLink,
          status: "Requested",
          userId: userId,
          price: amount,
          products: cartItems[0].name,
          deliveryFile: null,
          // product: descri,
        },
        { merge: true }
      );
    } catch (error) {
      console.log("error creating the order", error.message);
    }
  }
  // const batch = writeBatch(db);

  // const docRef = doc(collectionRef, orderId);
  // batch.set(docRef, objectsToAdd);

  // await batch.commit();

  // console.log("done");
};

//get Order History

export const handleGetUserOrderHistory = async () => {
  // console.log(uid);
  let collectionRef = collection(db, "orders");

  const q = query(
    collectionRef,
    // where("userId", "==", uid),
    orderBy("createdAt", "desc")
  );
  const querySnapshot = await getDocs(q);

  const orderHistoryMap = [
    ...querySnapshot.docs.map((docSnapshot) => {
      const {
        createdAt,
        deliveryAt,
        products,
        customername,
        email,
        phone,
        status,
        downloadLink,
        jobtarget,
        deliveryFile,
        userId,
      } = docSnapshot.data();

      return {
        id: docSnapshot.id,
        createdAt,
        deliveryAt,
        products,
        customername,
        email,
        phone,
        status,
        downloadLink,
        jobtarget,
        deliveryFile,
        userId,
      };
    }),
  ];

  // reduce((acc, docSnapshot) => {
  //   const { createdAt, deliveryAt, price, products, status } =
  //     docSnapshot.data();

  //   acc = {
  //     orderID: docSnapshot.id,
  //     createdAt,
  //     deliveryAt,
  //     products,
  //     status,
  //     price,
  //   };
  //   return acc;
  // }, {});

  // const

  return orderHistoryMap;
};

export const handleUploadDelivery = async (objectsToAdd, orderId) => {
  const orderDocRef = doc(db, "orders", orderId);
  console.log(orderDocRef);
  const orderSnapShot = await getDoc(orderDocRef);

  if (orderSnapShot.exists()) {
    // if (createdAt.getTime() > date2.getTime())
    const { deliveryFile } = objectsToAdd;
    try {
      await setDoc(
        orderDocRef,
        {
          deliveryFile,
          status: "Ready",
        },
        { merge: true }
      );
    } catch (error) {
      console.log("error creating the order", error.message);
    }
  }

  return orderSnapShot;
};

// export const getProductAndDocuments = async () => {
//   const collectionRef = collection(db, "products");
//   const q = query(collectionRef);

//   const querySnapshot = await getDocs(q);
//   const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
//     const { name, popular, price, subtitle, type, id, coverages } =
//       docSnapshot.data();
//     acc[name.toLowerCase()] = {
//       popular,
//       price,
//       subtitle,
//       name,
//       type,
//       id,
//       coverages,
//     };
//     return acc;
//   }, {});

//   return categoryMap;
// };

export const handdleUpdateStatus = async (orderId, status) => {
  const orderDocRef = doc(db, "orders", orderId);
  console.log(orderDocRef);
  const orderSnapShot = await getDoc(orderDocRef);
  if (orderSnapShot.exists()) {
    // if (createdAt.getTime() > date2.getTime())
    try {
      await setDoc(
        orderDocRef,
        {
          status: status,
        },
        { merge: true }
      );
    } catch (error) {
      console.log("error creating the order", error.message);
    }
  }

  return orderSnapShot;
};
