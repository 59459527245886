import { createAction } from "../../utils/reducer/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";

export const setCurrentUser = (user) =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user);

// CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
// EMAIL_SIGN_IN_START: "user/EMAIL_SIGN_IN_START",
// SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
// SIGN_IN_FAILURE: "user/SIGN_IN_FAILURE",

// SIGN IN ACTION

export const checkUserSession = () =>
  createAction(USER_ACTION_TYPES.CHECK_USER_SESSION);

export const emailSignInStart = (email, password) =>
  createAction(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, { email, password });

export const signInSuccess = (user) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, user);

export const signInFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, error);

// SIGN UP ACTION

export const signUpStart = (email, password, additionalDetails) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_START, {
    email,
    password,
    additionalDetails,
  });

export const signUpSuccess = (user, additionalDetails) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_SUCCESS, { user, additionalDetails });

export const signUpFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, error);

// SIGN OUT ACTION

export const signOutStart = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_START);

export const signOutSuccess = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS);

export const signOutFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_FAILED, error);

// UPDATE PROFILE ACTION

export const updateProfileStart = (user, additionalDetails) =>
  createAction(USER_ACTION_TYPES.UPDATE_PROFILE_START, {
    user,
    additionalDetails,
  });

export const updateProfileSuccess = (user) =>
  createAction(USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS, user);

export const updateProfileFailed = (error) =>
  createAction(USER_ACTION_TYPES.UPDATE_PROFILE_FAILED, error);

export const reauthenticationStart = (email, password) =>
  createAction(USER_ACTION_TYPES.RE_AUTH_START, { email, password });
// console.log(email);
// console.log(password);

export const reauthenticationSuccess = (credential) =>
  createAction(USER_ACTION_TYPES.RE_AUTH_SUCCESS, credential);

export const reauthenticationFailed = (error) =>
  createAction(USER_ACTION_TYPES.RE_AUTH_FAILED, error);

export const updatePasswordStart = (newPassword) =>
  createAction(USER_ACTION_TYPES.UPDATE_PASSWORD_START, newPassword);

export const updatePasswordSuccess = () =>
  createAction(USER_ACTION_TYPES.UPDATE_PASSWORD_SUCCESS);

export const updatePasswordFailed = (error) =>
  createAction(USER_ACTION_TYPES.UPDATE_PASSWORD_FAILED, error);

export const updateEmailStart = (newEmail) =>
  createAction(USER_ACTION_TYPES.UPDATE_EMAIL_START, newEmail);

export const updateEmailSuccess = (newEmail) =>
  createAction(USER_ACTION_TYPES.UPDATE_EMAIL_SUCCESS, newEmail);

export const updateEmailFailed = (error) =>
  createAction(USER_ACTION_TYPES.UPDATE_EMAIL_FAILED, error);

export const updateEmailDatabaseSuccess = (user) =>
  createAction(USER_ACTION_TYPES.UPDATE_EMAIL_DATABASE_SUCCESS, user);

export const resetPasswordStart = (email) =>
  createAction(USER_ACTION_TYPES.RESET_PASSWORD_START, email);
