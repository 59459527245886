import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  /* flex-direction: column; */

  /* background-color: lightblue; */
`;

export const DashboardContent = styled.div`
  flex: 8;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 8fr;

  background-color: #f5f5f5;
`;
