import { USER_ACTION_TYPES } from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  credential: null,
  isLoading: false,
  error: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
    case USER_ACTION_TYPES.UPDATE_EMAIL_DATABASE_SUCCESS:
    case USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      return { ...state, currentUser: payload, error: null };
    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return { ...state, currentUser: null, error: null };
    case USER_ACTION_TYPES.RE_AUTH_SUCCESS:
      return { ...state, credential: payload };
    case USER_ACTION_TYPES.RE_AUTH_FAILED:
    case USER_ACTION_TYPES.SIGN_IN_FAILED:
      switch (payload.code) {
        case "auth/wrong-password":
          return { ...state, error: "The password is invalid" };
        case "auth/user-not-found":
          return {
            ...state,
            error:
              "There is no existing user record corresponding to the provided identifier.",
          };
        case "auth/too-many-requests":
          return {
            ...state,
            error:
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later",
          };
        default:
          return {
            ...state,
            error:
              "Your Password is incorrect or this account doesn't exist, Please try again!!",
          };
      }
    case USER_ACTION_TYPES.SIGN_OUT_FAILED:
    case USER_ACTION_TYPES.SIGN_UP_FAILED:
      return { ...state, error: payload };
    default:
      return state;
  }
};
