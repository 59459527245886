import { useState, FormEvent, ChangeEvent, Fragment, useEffect } from "react";
import { useFormik } from "formik";

import FormInput from "../form-input/form-input.component";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

import {
  SignInContainer,
  ButtonsContainer,
  SignInNForgot,
  ErrorContext,
  ClickLink,
} from "./sign-in-form.styles";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
// import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
// import SignUpForm from "../sign-up-form/sign-up-form.component";
import { emailSignInStart } from "../../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";

import { signInSchema } from "../../validations/uploadValidation";
import { selectCurrentUser, selectError } from "../../store/user/user.selector";
import ForgetPassword from "../forget-password/forget-password.component";

// const defaultFormFields = {
//   email: "",
//   password: "",
// };

const SignInForm = () => {
  const userError = useSelector(selectError);
  const currentUser = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser.uid);
      navigate("/account");
    } else {
      navigate("/");
    }
    //
  }, [currentUser]);

  const onSubmit = async (values, actions) => {
    // event.preventDefault();

    try {
      // const response = await signInAuthUserWithEmailAndPassword(
      //   email,
      //   password
      // );

      // console.log(values);
      dispatch(emailSignInStart(values.email, values.password));
      actions.resetForm();
      // navigate("/account");
      // resetFormFields();
      // if (currentUser) {
      //   // if (!children.nextpath) {
      //   navigate("/account");
      //   // }
      // }

      // navigate(path);
    } catch (error) {
      console.log("user sign in failed", error);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: signInSchema,
      onSubmit,
    });

  const dispatch = useDispatch();

  const [IsSignUp, setIsSignUp] = useState(false);
  const [IsForgetPass, setIsForgetPass] = useState(false);

  return (
    <Fragment>
      {IsSignUp ? (
        // <SignUpForm />
        <div></div>
      ) : (
        <SignInContainer>
          {IsForgetPass ? (
            <ForgetPassword />
          ) : (
            <Fragment>
              <h2>sign in</h2>
              <h1>Welcome</h1>

              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Email"
                  type="email"
                  required
                  onChange={handleChange}
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  className={errors.email && touched.email ? "input-error" : ""}
                />
                {errors.email && touched.email && (
                  <p className="error">{errors.email}</p>
                )}

                <FormInput
                  label="Password"
                  type="password"
                  required
                  onChange={handleChange}
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password ? "input-error" : ""
                  }
                />

                {errors.password && touched.password && (
                  <p className="error">{errors.password}</p>
                )}

                <SignInNForgot>
                  <ClickLink
                    onClick={() => {
                      setIsSignUp(true);
                    }}
                  >
                    Register
                  </ClickLink>
                  <ClickLink
                    onClick={() => {
                      setIsForgetPass(true);
                    }}
                  >
                    Forget Password
                  </ClickLink>
                </SignInNForgot>
                {userError && (
                  <ErrorContext>
                    <ErrorOutlinedIcon />
                    <div>{userError}</div>
                  </ErrorContext>
                )}

                <ButtonsContainer>
                  <Button type="submit">Sign In</Button>
                </ButtonsContainer>
              </form>
            </Fragment>
          )}
        </SignInContainer>
      )}
    </Fragment>
  );
};
export default SignInForm;
