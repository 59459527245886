export const ORDER_ACTION_TYPES = {
  SAVE_ORDER_HISTORY_START: "orders/SAVE_ORDER_HISTORY_START",
  SAVE_ORDER_HISTORY_FAILED: "user/SAVE_ORDER_HISTORY_FAILED",
  GET_USER_ORDER_HISTORY_START: "orders/GET_USER_ORDER_HISTORY_START",
  SET_USER_ORDER_HISTORY: "orders/SET_USER_ORDER_HISTORY",
  UPDATE_STATUS_ORDER_START: "orders/UPDATE_STATUS_ORDER_START",
  UPDATE_STATUS_ORDER_SUCCESS: "orders/UPDATE_STATUS_ORDER_SUCCESS",
  UPDATE_STATUS_ORDER_FAILED: "orders/UPDATE_STATUS_ORDER_FAILED",
  UPLOAD_DELIVERY_FILE_START: "orders/UPLOAD_DELIVERY_FILE_START",
  UPLOAD_DELIVERY_FILE_SUCCESS: "orders/UPLOAD_DELIVERY_FILE_SUCCESS",
  UPLOAD_DELIVERY_FILE_FAILED: "orders/UPLOAD_DELIVERY_FILE_FAILED",
};
