import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileStart } from "../../store/user/user.action";
import { selectCurrentUser } from "../../store/user/user.selector";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import UpdateEmailModal from "../updateEmailPasswordModal/update-email-modal.component";

import {
  ProfileContainer,
  ProfileContent,
  ProfileCard,
  ProfileTitle,
} from "./user-profile.styles";
const defaultFormFields = {
  firstname: "",
  middlename: "",
  lastname: "",
  // email: "",
  phone: "",
};

const UserProfile = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  // const defaultFormFields = {
  //   firstname: currentUser ? currentUser.firstname : "",
  //   middlename: currentUser ? currentUser.middlename : "",
  //   lastname: currentUser ? currentUser.lastname : "",
  //   // email: "",
  //   phone: currentUser ? currentUser.phone : "",
  // };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isUpdate, setIsUpdate] = useState(false);
  const { firstname, middlename, lastname, phone } = formFields;

  const [modalOpen, setModalOpen] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const close = () => setModalOpen(false);
  const open = (type) => {
    setModalOpen(true);
    setUpdateType(type);
  };

  useEffect(() => {
    if (currentUser) {
      let currentUserformFields = {
        firstname: currentUser.firstname || "",
        middlename: currentUser.middlename || "",
        lastname: currentUser.lastname || "",
        // email: currentUser ? currentUser.email : email,
        phone: currentUser.phone || "",
      };
      setFormFields(currentUserformFields);
    }
  }, [currentUser]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdate(true);
    try {
      console.log(currentUser);
      dispatch(
        updateProfileStart(currentUser, {
          firstname,
          middlename,
          lastname,
          phone,
        })
      );
      setIsUpdate(false);
    } catch (error) {
      // if (error.code === "auth/email-already-in-use") {
      //   alert("Cannot create user, email already in use");
      // } else {
      console.log("Update Profile Information Failed", error);
      // }
    }
  };
  return (
    <ProfileContainer>
      <ProfileCard>
        <ProfileContent>
          <form onSubmit={handleSubmit}>
            <ProfileTitle>Personal Information</ProfileTitle>
            <FormInput
              label="First Name"
              type="text"
              required
              onChange={handleChange}
              name="firstname"
              value={firstname}
            />

            <FormInput
              label="Middle Name"
              type="text"
              required
              onChange={handleChange}
              name="middlename"
              value={middlename}
            />

            <FormInput
              label="Last Name"
              type="text"
              required
              onChange={handleChange}
              name="lastname"
              value={lastname}
            />

            <FormInput
              label="Phone"
              type="tel"
              required
              onChange={handleChange}
              name="phone"
              value={phone}
            />

            <Button type="submit"> update</Button>
          </form>
        </ProfileContent>

        <ProfileContent>
          <ProfileTitle>Login</ProfileTitle>
          <div>
            <p>Email</p>
            <Button
              isLoading={false}
              buttonType={BUTTON_TYPE_CLASSES.upload}
              onClick={() => (modalOpen ? close() : open("email"))}
            >
              Update Email
            </Button>
          </div>
          <div>
            <p>Password</p>
            <Button
              isLoading={false}
              buttonType={BUTTON_TYPE_CLASSES.upload}
              onClick={() => (modalOpen ? close() : open("password"))}
            >
              Update Password
            </Button>
          </div>
        </ProfileContent>
      </ProfileCard>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalOpen && (
          <UpdateEmailModal
            modalOpen={modalOpen}
            handleClose={close}
            updateType={updateType}

            // handleYes={handdleRefundOrder}
            // isCancelLoading={isCancelLoading}
            // title="Are you sure you want to cancel the order?"
            // subtitle="If yes, our system will start processing the request and make a refund to the card you store with us. Please expect 5-10 business days for the request to be completed"
          />
        )}
      </AnimatePresence>
    </ProfileContainer>
  );
};
export default UserProfile;
