// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getStorage } from "firebase/storage";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  writeBatch,
  query,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhMh-JIKcYiER8K-FmxssOBNbzEIosn1E",
  authDomain: "coaon2-bb14d.firebaseapp.com",
  projectId: "coaon2-bb14d",
  storageBucket: "coaon2-bb14d.appspot.com",
  messagingSenderId: "526775075710",
  appId: "1:526775075710:web:390e304450dead49f6292f",
  measurementId: "G-S4QZSJ1B3D",
};

// Initialize Firebase
const fribaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});

// Firebase storage reference
export const storage = getStorage(fribaseApp);

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const db = getFirestore();

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd,
  field
) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
  console.log("done");
};

// Get all product
export const getProductAndDocuments = async () => {
  const collectionRef = collection(db, "products");
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
    const { name, popular, price, subtitle, type, id, coverages } =
      docSnapshot.data();
    acc[name.toLowerCase()] = {
      popular,
      price,
      subtitle,
      name,
      type,
      id,
      coverages,
    };
    return acc;
  }, {});

  return categoryMap;
};

export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  const userDocRef = doc(db, "writers", userAuth.uid);
  const userSnapShot = await getDoc(userDocRef);

  if (!userSnapShot.exists()) {
    const { displayName, email, uid } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        uid,
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }

  return userSnapShot;
};

export const updateUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  const userDocRef = doc(db, "writers", userAuth.uid);
  let userSnapShot = await getDoc(userDocRef);

  if (userSnapShot.exists()) {
    const { email, uid } = userAuth;
    // const createdAt = new Date();

    try {
      await setDoc(
        userDocRef,
        {
          email,
          uid,
          // createdAt,
          ...additionalInformation,
        },
        { merge: true }
      );
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }
  userSnapShot = await getDoc(userDocRef);

  return userSnapShot;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const reauth = async (email, password) => {
  if (!email || !password) return;

  const credential = EmailAuthProvider.credential(email, password);

  return await reauthenticateWithCredential(auth.currentUser, credential);
};

export const updateNewPassword = async (newPassword) => {
  // console.log("update Start 2");

  await updatePassword(auth.currentUser, newPassword);
};

export const updateUserEmail = async (newEmail) => {
  console.log("update Email Start 2");
  return await updateEmail(auth.currentUser, newEmail)
    .then(() => {
      // Email updated!
      // console.log("update 3");
      // console.log(auth.currentUser);
      // ...
      return "Email Update!!";
    })
    .catch((error) => {
      return error;
      // An error occurred
      // ...
    });
};

export const updateUserEmailDatabase = async (email) => {
  const userDocRef = doc(db, "writers", auth.currentUser.uid);
  const userSnapShot = await getDoc(userDocRef);

  if (userSnapShot.exists()) {
    // const { uid } = auth.currentUser;
    // const createdAt = new Date();

    try {
      await setDoc(
        userDocRef,
        {
          email,
        },
        { merge: true }
      );
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }

  return userSnapShot;
};

export const signOutUser = async () => {
  await signOut(auth);
};

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};
