import styled from "styled-components";

export const NavbarContainer = styled.div`
  /* height: 100px; */
  /* flex: 6; */
  /* display: flex;
  align-items: center; */
  /* padding: 0 20px; */
  background-color: white;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  height: 50px;
  width: auto;
  border-radius: 25px;
  background-color: #f2f2f2;
  input {
    border: none;
    outline: none;
    background: transparent;
    padding: 0 20px;
    width: 200px;
    &::placeholder {
      font-size: 14px;
    }
  }
  .searchIcon {
    color: #929292;
    font-size: 30px;
  }
`;

export const TitileContainer = styled.div`
  width: 400px;
  margin-right: 40px;
`;
export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Item = styled.div`
  margin-right: 20px;
`;
export const DisplayName = styled.p`
  color: #0b1538;
  font-weight: 700;
`;
