import { SpinnerContainer, SpinnerOverlay } from "./spinner.styles";
import CircularProgress from "@mui/material/CircularProgress";
const Spinner = () => (
  <SpinnerOverlay>
    <CircularProgress color="inherit" size={30} />
  </SpinnerOverlay>
);

export default Spinner;

// <SpinnerContainer />
