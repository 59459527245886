import styled from "styled-components";

export const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  /* flex: 1; */

  .statusStyle {
    padding: 8px;
    border-radius: 5px;
    font-weight: 700;

    &.ready {
      color: #2dc8f8;
      background-color: rgba(45, 200, 248, 0.17);
    }

    &.active {
      color: #59aaa7;
      background-color: rgba(72, 161, 158, 0.17);
    }

    &.inProgress {
      color: #8c5dea;
      background-color: rgba(140, 93, 234, 0.17);
    }
    &.moreInfo {
      color: #eb9d32;
      background-color: rgba(248, 208, 76, 0.17);
    }
    &.canceled {
      color: #ea5e5d;
      background-color: rgba(234, 94, 93, 0.17);
    }
  }

  .edit-icon {
    margin-left: 5px;
    cursor: pointer;
  }

  .price {
    color: #3377ff;
    font-weight: 700;
  }

  .contact {
    display: flex;
    justify-content: center;
  }

  .contact .icon {
    color: #2b56f3;
    cursor: pointer;
  }
  .downloadlink {
    .icon {
      font-size: 16px;
      margin-right: 3px;
    }
    a {
      display: flex;
      /* justify-content: center; */
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .status-container {
    display: flex;
    align-items: center;

    .edit-icon {
      margin-left: 10px;
      color: #2b56f3;
    }

    .moreInfo-status-icon {
      margin-left: 5px;
      color: #eb9d32;
      cursor: pointer;
    }
    .inprogress-status-icon {
      margin-left: 5px;
      color: #8c5dea;
      cursor: pointer;
    }

    .ready-status-icon {
      margin-left: 5px;
      color: #2dc8f8;
      cursor: pointer;
    }

    .cancle-status-icon {
      margin-left: 5px;
      color: #ea5e5d;
      cursor: pointer;
    }
  }
`;
