import { Fragment, useState } from "react";
import Button from "../../button/button.component";
import FormInput from "../../form-input/form-input.component";
import {
  ButtonsContainer,
  ClickLink,
  SignInContainer,
  SignInNForgot,
} from "../../sign-in-form/sign-in-form.styles";
import { LoginModalContainer } from "./verify-login.styles";

const LoginModal = ({ formData, setFormData }) => {
  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { email, password } = formFields;
  // const navigate = useNavigate();
  // const [IsSignUp, setIsSignUp] = useState(false);
  const [IsForgetPass, setIsForgetPass] = useState(false);
  // const resetFormFields = () => {
  //   setFormFields(defaultFormFields);
  // };

  // const handleSubmit = () => {};

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <LoginModalContainer>
        <div>
          <FormInput
            label="Email"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={formData.email}
            disabled
            style={{ backgroundColor: "#cbcbcb", color: "#696969" }}

            // "background-color: gray; color: #0b1538;"}
          />

          <FormInput
            label="Password"
            type="password"
            required
            onChange={handleChange}
            name="password"
            value={formData.password}
          />
        </div>
      </LoginModalContainer>
    </Fragment>
  );
};
export default LoginModal;

// <ButtonsContainer>
//   <Button type="submit">Next</Button>
// </ButtonsContainer>;
