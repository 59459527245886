import { motion } from "framer-motion";
import Backdrop from "../backdrop/backdrop.component";
// import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import Upload from "../upload/upload.component";
import "./modal.styles.scss";

const Modal = ({
  handleClose,
  title,
  subtitle,
  readyFile,
  handleYes,
  isCancelLoading,
  orderId,
  userId,
}) => {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  // console.log(userId);
  // console.log(orderId);
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal modalCard"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1>{title}</h1>

        <div className="deliveredFile">
          <div>Files:</div>
          {readyFile ? (
            <div>
              {readyFile.map((file) => (
                <div className="file" key={file.filename}>
                  {file.filename}
                </div>
              ))}
            </div>
          ) : (
            <div>No file uploaded </div>
          )}
        </div>

        <Upload handleClose={handleClose} userId={userId} orderId={orderId} />
      </motion.div>
    </Backdrop>
  );
};

export default Modal;

// <div className="buttonContainer">
//   <Button
//     isLoading={isCancelLoading}
//     buttonType={BUTTON_TYPE_CLASSES.modalYes}
//     onClick={handleYes}
//   >
//     Yes
//   </Button>
//   <Button
//     isLoading={false}
//     buttonType={BUTTON_TYPE_CLASSES.modalNo}
//     onClick={handleClose}
//   >
//     Close
//   </Button>
// </div>;
