import { motion } from "framer-motion";
import { Fragment, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Backdrop from "../backdrop/backdrop.component";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import {
  ButtonsContainer,
  ClickLink,
  SignInContainer,
  SignInNForgot,
} from "../sign-in-form/sign-in-form.styles";
import LoginModal from "./verify-login/verify-login.component";
import "./updae-modal.styles.scss";

import UpdateEmailForm from "./update-email-form/update-email-form.component";
import UpdatePasswordForm from "./update-password-form/update-password.component-form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import {
  emailSignInStart,
  reauthenticationStart,
  updateEmailStart,
  updatePasswordStart,
} from "../../store/user/user.action";
import { reauth } from "../../utils/firebase/firebase.utils";
import { useFormik } from "formik";
import { signInSchema } from "../../validations/uploadValidation";

const UpdateEmailModal = ({ handleClose, updateType }) => {
  const currentUser = useSelector(selectCurrentUser);
  const defaultFormFields = {
    email: currentUser.email,
    password: "",
    newemail: "",
    // currentpassword: "",
    newpassword: "",
    comfirmnewpassword: "",
    credential: "",
  };

  const dispatch = useDispatch();

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { email, password } = formFields;
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState(defaultFormFields);

  const {
    email,
    password,
    newemail,
    newpassword,
    comfirmnewpassword,
    credential,
  } = formData;

  const FormTitles = [
    "Please verify your account",
    "Update Your Email",
    "Update New Password",
  ];

  const FormSubTitles = [
    "to continue edit your profile",
    "Enter new email into the field",
    "Enter new password & verify new password",
  ];

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <LoginModal formData={formData} setFormData={setFormData} />;
      case 1:
        if (updateType === "email") {
          return (
            <UpdateEmailForm formData={formData} setFormData={setFormData} />
          );
        } else {
          return (
            <UpdatePasswordForm formData={formData} setFormData={setFormData} />
          );
        }

      default:
    }
  };

  const resetFormData = () => {
    setFormData(defaultFormFields);
  };

  const handleClick = async () => {
    if (page === 1) {
      if (credential && updateType === "email") {
        if (!newemail) return;
        // console.log(newemail);
        try {
          dispatch(updateEmailStart(newemail));
          await resetFormData();
          alert("Your Email has been updated");
          handleClose();
        } catch (error) {
          console.log(error);
        }
      } else {
        if (!newpassword || !comfirmnewpassword) return;
        if (newpassword === comfirmnewpassword) {
          try {
            dispatch(updatePasswordStart(newpassword));
            await resetFormData();
            alert("Your Password has been updated");
            handleClose();
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("Password is not the same as comfirm password");
        }
        console.log(newpassword);
        console.log(comfirmnewpassword);
      }
      // alert("FORM SUBMITTED");
      console.log(formData);
    } else if (page === 0) {
      if (!password) return;

      console.log(email, password);
      // dispatch(emailSignInStart(email, password));
      const credentialSnapshot = await reauth(email, password);
      await setFormData({ ...formData, ["credential"]: credentialSnapshot });

      // dispatch(reauthenticationStart(email, password));
      setPage((currPage) => currPage + 1);
    } else {
      setPage((currPage) => currPage + 1);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal modalCard"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="form-container">
          <div className="header">
            <h1>
              {updateType === "password" && page === 1
                ? FormTitles[2]
                : FormTitles[page]}
            </h1>
            <h2>
              {updateType === "password" && page === 1
                ? FormSubTitles[2]
                : FormSubTitles[page]}
            </h2>
          </div>
          <div className="body">{PageDisplay()}</div>
          <div className="footer">
            <div hidden={page === 0}>
              <Button
                onClick={() => {
                  setPage((currPage) => currPage - 1);
                }}
              >
                Prev
              </Button>
            </div>
            <Button onClick={handleClick}>
              {page === 1 ? "Update" : "Next"}
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateEmailModal;
