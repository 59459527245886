import { useFormik } from "formik";
import { Fragment } from "react";
import { forgetPasswordSchema } from "../../validations/uploadValidation";
import Button from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import { ButtonsContainer } from "./forget-password.styles";

const ForgetPassword = () => {
  const onSubmit = async (values, actions) => {
    // event.preventDefault();

    try {
      // const response = await signInAuthUserWithEmailAndPassword(
      //   email,
      //   password
      // );

      console.log(values);
      // dispatch(emailSignInStart(values.email, values.password));
      // actions.resetForm();
    } catch (error) {
      console.log("password reset failed", error);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgetPasswordSchema,
      onSubmit,
    });
  return (
    <Fragment>
      <h2>Forget Password</h2>
      <h1>Reset Your Password</h1>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          type="email"
          required
          onChange={handleChange}
          name="email"
          value={values.email}
          onBlur={handleBlur}
          className={errors.email && touched.email ? "input-error" : ""}
        />
        {errors.email && touched.email && (
          <p className="error">{errors.email}</p>
        )}

        <ButtonsContainer>
          <Button type="submit">Reset Password</Button>
        </ButtonsContainer>
      </form>
    </Fragment>
  );
};

export default ForgetPassword;
