import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ProfileCard = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 25px;
  background-color: white;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding: 50px;
`;

export const ProfileContent = styled.div`
  width: 100%;
  padding: 30px;
  border: 1px solid rgba(112, 112, 112, 0.2);
`;

export const ProfileTitle = styled.h2`
  font-size: 30px;
  color: #0b1538;
`;
