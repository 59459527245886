import { takeLatest, all, call, put } from "redux-saga/effects";
// import { emptyCart } from "../cart/cart.action";
import {
  saveOrderHistoryFailed,
  setUserOrderHistory,
  updateStatusOrderFailed,
  updateStatusOrderSuccess,
  uploadDeliveryFileFailed,
  uploadDeliveryFileSuccess,
} from "./orders.action";
import {
  addOrder,
  handdleUpdateStatus,
  handleGetUserOrderHistory,
  handleUploadDelivery,
} from "./orders.helpers";
import { ORDER_ACTION_TYPES } from "./orders.types";

export function* saveOrder({
  payload: { collectionKey, objectsToAdd, orderId, userId, amount, cartItems },
}) {
  try {
    yield call(
      addOrder,
      collectionKey,
      objectsToAdd,
      orderId,
      userId,
      amount,
      cartItems
    );
    // yield put(emptyCart());
  } catch (error) {
    yield put(saveOrderHistoryFailed(error));
  }
}

// export function* signUp({ payload: { email, password, additionalDetails } }) {
//   try {
//     const { user } = yield call(
//       createAuthUserWithEmailAndPassword,
//       email,
//       password
//     );

//     yield put(signUpSuccess(user, additionalDetails));
//   } catch (error) {
//     yield put(signUpFailed(error));
//   }
// }
export function* getUserOrderHistory() {
  try {
    // console.log(payload);
    const history = yield call(handleGetUserOrderHistory);

    yield put(setUserOrderHistory(history));
  } catch (error) {
    console.log(error);
  }
}

export function* updateStatus({ payload: { orderId, status } }) {
  try {
    const updatesnapshot = yield call(handdleUpdateStatus, orderId, status);
    // yield put(
    //   updateStatusOrderSuccess({ id: history.data().id, ...history.data() })
    // );
    yield put(updateStatusOrderSuccess());
    // yield put(refreshData(uid));
  } catch (error) {
    yield put(updateStatusOrderFailed(error));
  }
}

export function* refreshData() {
  try {
    const history = yield call(handleGetUserOrderHistory);
    yield put(setUserOrderHistory(history));
  } catch (error) {
    yield put(updateStatusOrderFailed(error));
  }
}

export function* uploadDeliveryFile({ payload: { objectsToAdd, orderId } }) {
  // console.log("start1");
  try {
    // console.log(objectsToAdd);
    // console.log(orderId);
    const updatesnapshot = yield call(
      handleUploadDelivery,
      objectsToAdd,
      orderId
    );
    // console.log(updatesnapshot);
    yield put(uploadDeliveryFileSuccess());
  } catch (error) {
    yield put(uploadDeliveryFileFailed(error));
  }
}

export function* onGetUserOrderHistoryastart() {
  yield takeLatest(
    ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_START,
    getUserOrderHistory
  );
}

export function* onSaveOrderHistoryStart() {
  yield takeLatest(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_START, saveOrder);
}

export function* onUpdateStatusStart() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_START, updateStatus);
}

export function* onUpdateStatusSuccess() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS, refreshData);
}

export function* onUploadDeliveryFileStart() {
  yield takeLatest(
    ORDER_ACTION_TYPES.UPLOAD_DELIVERY_FILE_START,
    uploadDeliveryFile
  );
}

export function* onUploadDeliveryFileSuccess() {
  yield takeLatest(
    ORDER_ACTION_TYPES.UPLOAD_DELIVERY_FILE_SUCCESS,
    refreshData
  );
}

export function* ordersSagas() {
  yield all([
    call(onSaveOrderHistoryStart),
    call(onGetUserOrderHistoryastart),
    call(onUpdateStatusStart),
    call(onUpdateStatusSuccess),
    call(onUploadDeliveryFileStart),
    call(onUploadDeliveryFileSuccess),
  ]);
}
