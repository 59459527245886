import {
  NavbarContainer,
  Wrapper,
  SearchContainer,
  TitileContainer,
  RightWrapper,
  Item,
  DisplayName,
  LeftWrapper,
} from "./navbar-dashboard.styled";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const NavbarDashboard = () => {
  return (
    <NavbarContainer>
      <Wrapper>
        <LeftWrapper>
          <TitileContainer>
            <h1>Dashboard</h1>
          </TitileContainer>
        </LeftWrapper>
        <RightWrapper>
          <SearchContainer>
            <input type="text" placeholder="Search..." />
            <SearchOutlinedIcon className="searchIcon" />
          </SearchContainer>
        </RightWrapper>
      </Wrapper>
    </NavbarContainer>
  );
};
export default NavbarDashboard;

// <Item>
// <DisplayName>Modern LastName</DisplayName>
// </Item>
